import React, { FC } from 'react';
import { Edit, SimpleForm, TextInput } from 'react-admin';

export const EditComponent: FC = () => {
	return (
		<Edit>
			<SimpleForm>
				<TextInput source="username" />
				<TextInput source="firstName" />
				<TextInput source="lastName" />
				<TextInput source="email" />
				<TextInput source="aboutText" />
				<TextInput source="roles" />
			</SimpleForm>
		</Edit>
	);
};
